const messages = {
    zh_CN: {
        app_name: '几度旅行',
        home: '首页',
        hotel_index: '预订酒店',
        hotel_list: '酒店搜索',
        hotel_detail: '酒店详细',
        booking_list: '订单管理',
        ticket_list: '工单管理',
        booking_detail: '订单详细',
        about_us: '关于我们',
        partners: '合作伙伴',
        contact_us: '联系我们',
        recommended_hotels: '推荐酒店',
        login: '登录',
        logout: '退出',
        submit: '提交',
        search: '搜索',
        reset: '重置',
        back: '返回',
        next: '下一步',
        update: '修改',
        cancel: '取消',
        close: '关闭',
        delete: '删除',
        print: '打印',
        create: '创建',
        append: '追加',
        upload: '上传',
        ok: '确定',
        all: '全部',
        filters: '筛选',
        clear_all: '清除',
        and: '和',
        current: '当前',
        detail: '详细',
        register: '注册',
        username: '用户名',
        password: '密码',
        confirmemail: '邮箱验证',
        new_password: '新密码',
        re_new_password: '确认新密码',
        input_username: '请输入您的用户名',
        input_password: '请输入您的密码',
        passwords_not_match: '两个密码不一致',
        passwords_min_length: '密码必须包含10个字符',
        loginname_min_length: '用户名长度必须为8-16个字符',
        exist_login_name: '{s} 已被注册，请使用其他用户名',
        exist_user_email: '{s} 已被注册，请更换其他',
        remember_me: '记住我',
        forgot_password: '忘记密码',
        'find-password': '找回密码',
        reset_password: '重置密码',
        no_account_register: '没有账号? 注册',
        login_expired: '登录已失效/未登录，请重新登录',
        user_email: '用户邮箱',
        user_info: '用户信息',
        vercode: '验证码',
        send_email_code: '发送邮箱验证码',
        send_email_code_success: '验证码发送成功, 请注意查收',
        resend_after_s: '{s}s后重新发送',
        register_welcome: '欢迎注册几度账号',
        register_welcome_desc: '我们会在24小时内核对您的注册信息，届时请您留意注册邮箱。',
        register_email_confirm: '您的账号已经注册，请进行邮箱验证',
        register_email_confirm_desc: '我们已向{s}发送了确认邮件，请通过邮件内的链接进行验证，验证成功后我们将在24小时内完成审核并以邮件通知您。',
        register_email_confirm_error: '邮箱验证失败',
        register_info: '注册信息',
        register_success: '您的账号注册成功',
        email_authentication: '邮箱验证',
        info_verification: '信息审核',
        company_info: '企业信息',
        company_code: '企业代码',
        company_name: '企业名称',
        enterprise_agency: '企业机构',
        personal_agent: '个人同行',
        personal_info: '个人信息',
        company_city: '所在城市',
        contact_address: '联系地址',
        contact_tel: '联系电话',
        contact_person: '联系人',
        account_info: '账号信息',
        full_name: '姓名',
        mobile_number: '手机号码',
        booking_rules: '预订规则',
        eps_terms: '供应商预订条款',
        eps_terms_agent: '补充条款',
        faq: '常见问题',
        privacy_policy: '隐私政策',
        service_terms: '服务条款',
        wechat_official_account: '微信公众号',
        org_info:'机构信息',
        credit_balance: '授信余额',
        credit_quota: '授信额度',
        view_details: '查看明细',
        view_details1: '查看详细',
        more_detail: '更多详细',
        messages: '消息中心',
        settings: '个人设置',
        destination: '目的地',
        city: '城市',
        hotel: '酒店',
        hotel_desc: '酒店简介',
        checkin_date: '入住日期',
        checkout_date: '退房日期',
        checkin_time: '入住时间',
        checkout_time: '退房时间',
        hanlde_checkin_time: '入住办理时间',
        checkin: '入住',
        checkout: '退房',
        checkin_min_age: '单独办理入住需年满{n}岁',
        date: '日期',
        date_range: '日期范围',
        nights: '入住晚数',
        night_unit: '{n}晚',
        room_unit: '{n}间',
        adult_unit: '{n}成人',
        child_unit: '{n}儿童',
        year_old: '{n}岁',
        adult_unit_cn: '{n}成人',
        child_unit_cn: '{n}儿童',
        year_old_cn: '{n}岁',
        adult_unit_en: '1 adult | {n} adults',
        child_unit_en: '0 child | 1 child | {n} children',
        year_old_en: '0 year old | 1 year old | {n} years old',
        image_unit: '{n}张图片',
        hotel_unit: '找到 <span style="color: #FF5C00">{n}</span> 家酒店',
        rooms: '房间数',
        adults: '成人数',
        children: '儿童数',
        child_ages: '儿童年龄',
        per_room: '每间入住',
        nationality: '国籍',
        residency: '居住地',
        common_nationality: '常用国籍',
        other_nationality: '其他国籍',
        nationality_remark: '如实际入住人国籍、姓名与订单不符，可能会导致额外费用、无法变更和入住。',
        nationality_remark_eps: '如实际入住人居住地、姓名与订单不符，可能会导致额外费用、无法变更和入住。',
        success: '成功',
        more: '更多',
        hide: '隐藏',
        no_data_keyword: '没有找到任何数据，请输入其他关键字',
        an_least_2_char: '至少输入2个字符',
        total_items: '共 {s} 项',
        total_orders: '共 {s} 个订单',
        total_price: '总价',
        price: '价格',
        settlement_total_price: '结算价',
        no_limit: '不限',
        region: '区域',
        neighborhood: '地区/商圈',
        point_of_interest: '热门景点',
        star: '星级',
        brand: '品牌',
        guest_rating: '住客评分',
        guest_rating_sort: '住客好评优先',
        guest_reviews: '基于 {n} 条住客点评',
        guest_rating_options:{
            '4.5': '好极了 4.5+',
            '4': '非常好 4+',
            '3.5': '还不错 3.5+',
            '3': '一般般 3+',
        },
        g_overall: "综合评分",
        g_cleanliness: "清洁度评分",
        g_service: "员工服务",
        g_comfort: "客房舒适度",
        g_condition: "住宿环境",
        g_location: "位置便利度",
        g_neighborhood: "周边满意度",
        g_quality: "客房质量",
        g_value: "住宿性价比",
        g_amenities: "住宿服务设施",
        favorite: '收藏',
        service: '服务',
        getting_around: '周边',
        price_highest: '价格 高→低',
        price_lowest: '价格 低→高',
        hotel_info_loading: '酒店信息加载中...',
        confirm_price_loading: '正在确认价格，请稍候……',
        hot_selling_priority: '热销优先',
        favorite_priority: '收藏优先',
        star_lowest: '星级 低→高',
        star_highest: '星级 高→低',
        hotel_location: '酒店位置',
        hotel_overview: '酒店详情',
        hotel_policy: '酒店政策',
        important_information: '重要信息',
        guest_room: '客房',
        dining: '餐饮',
        hotel_size: '酒店规模',
        rec_spa_amenity: '休闲、SPA、高端服务设施',
        business_other_amenity: '商务及其他服务设施',
        renovation_close: '装修/关闭通知',
        check_out_airfare_combination_specials: '查看机票组合特价',
        airfare_combination_specials: '机票组合特价',
        hotel_name: '酒店名称',
        child_extra_beds: '儿童及加床',
        pet: '宠物',
        special_check_in_instructions: '特别入住说明',
        check_in_instructions: '入住说明',
        mandatory_fees: '强制税费',
        optional_extras: '可选项目费用',
        know_before_you_go: '入住须知',
        important_note: '住宿公告',
        other_policy: '其他',
        facility_service: '设施服务',
        room_price: '房型价格',
        room_size: '面积',
        room_type: '房型',
        room: '房间',
        all_room: '全部房型',
        cancel_policy: '取消政策',
        price_room_night: '每晚价格',
        maximum_occupancy: '最多可住{n}人',
        occupancy: '人数',
        occupancy_info: '入住人信息',
        reserve: '预订',
        reserve_now: '立即预订',
        select_bedtype: '选择床型',
        select_bedtype_warning: '酒店将优先为您安排选择的床型，但无法保证一定满足',
        bedtype_unit :{
            "double": '{n}张大床',
            "single": '{n}张单人床',
            "single-extra-bed": '{n}张单人床(加床)',
            "king": '{n}张超大床',
            "queen": '{n}张大床',
            "semi-double": '{n}小型双人床',
            "futon": '{n}张日式床垫',
            "sofa bed": '{n}张沙发床',
            "bunk":'{n}个铺位',
            "water bed": '{n}张水床',
            "waterbed": '{n}张水床',
        },
        meal_type: '餐型',
        included_meal: '含餐',
        non_refundable: '不可退改',
        non_refundable_remark: '该订单不可退改，当订单确认后，将无法进行取消或变更。',
        refundable: '限时取消',
        partially_refundable: '收费取消',
        free_cancel_before: '{str} 之前免费取消',
        cancel_policy_info: '从 {str} 起，将收取 {amount} 取消费用；',
        cancel_policy_info_1: '{s} 至 {e}，将收取 {f} 取消费用；',
        cancel_policy_info_2: '{e} 之后，将不可退改；',
        cancel_policy_info_3: '如果取消订单，将收取 {f} 取消费用。',
        cancellation_fee: '取消费用',
        beijing_time_remark:'以上均为北京时间',
        searching_for_price: '正在搜索价格，请稍候……',
        no_avail: '暂无报价',
        map_view: '查看地图',
        mealtypes: {
            '0':'不含餐',
            '1':'含早餐',
            '2':'含早晚餐',
            '3':'含早午餐',
            '4':'含三餐',
        },
        mealtype_unit: {
            '0':'不含餐',
            '1':'含早餐 × {n}',
            '2':'含早晚餐 × {n}',
            '3':'含早午餐 × {n}',
            '4':'含三餐 × {n}',
        },
        mealtype_unit_cn: {
            '0':'不含餐',
            '1':'含早餐 × {n}',
            '2':'含早晚餐 × {n}',
            '3':'含早午餐 × {n}',
            '4':'含三餐 × {n}',
        },
        mealtype_unit_en: {
            '0':'Room Only',
            '1':'Breakfast × {n}',
            '2':'Breakfast & Dinner × {n}',
            '3':'Breakfast & Lunch × {n}',
            '4':'Full Board × {n}',
        },
        booking_fill: '填写订单',
        booking_confirm: '确认订单',
        booking_pay: '支付订单',
        booking_info: '预订信息',
        booking_id: '订单号',
        immediately_confirm: '立即确认',
        immediately_confirm_remark: '订单提交后，酒店将立即确认您的订单，保障您的住房需求。',
        guest_name: '入住人',
        guest_name_main: '主入住人',
        guest_name_other: '其他入住人',
        last_name: '姓 (拼音)',
        first_name: '名 (拼音)',
        last_name_error: '仅限英文或拼音；不允许空格',
        first_name_error: '仅限英文或拼音',
        contact_info: '联系人信息',
        group_booking_id: '团单号',
        group_booking_id_remark: '不超过50个字符。只允许使用数字、字母、“/”、“-”。',
        special_request: '特殊需求',
        special_request_remark: '特殊需求无法保证一定满足，实际以酒店当天安排为准。',
        booking_reminder: '预订须知',
        rate_remark: '价格说明',
        pay_countdown_time: '请在{m}分{s}秒内完成支付，否则订单将被取消',
        price_change: '价格改变',
        from: '从 {str} 起',
        to: '至',
        room_rate: '房费',
        surcharges:'附加费用',
        taxes_fees:'税费',
        sales_tax:'销售税',
        property_fee:'酒店费用',
        above_total:'以上总计',
        jidu_service_charge:'我司服务费',
        pay_now: '现在支付',
        pay_hotel: '到店支付',
        air_ticket_info: '机票信息',
        air_ticket_info_remark: '已确认：上述旅客已经预定出行交通（航班、邮轮、租车或长途火车），在与旅客的沟通过程中，客房预定将始终与交通预定进行捆绑报价，旅客不会得知客房预定的单价。旅客预定的出行交通信息如下',
        flight_no: '航班号',
        air_ticket_no: '客票编号',
        air_departure: '起飞机场',
        air_landing: '落地机场',
        create_booking_loading: '正在生成订单，请稍候……',
        pay_booking_loading: '正在支付订单，请稍候……',
        booking_loading: '正在预订中，请稍候……',
        payment_method: '支付方式',
        confirm_pay: '确认支付',
        confirm_cancel: '确认取消订单',
        pay: '支付',
        paid_success: '已支付成功',
        delayed_payment: '延时支付',
        delayed_payment_desc: '延时支付说明',
        latest_payment_time: '最晚支付时间',
        scaning_payment: '微信扫码',
        scaning_remark: '使用微信APP扫码',
        alipay: '支付宝',
        booking_time: '预订时间',
        client_ref_id: '客户单号',
        address: '地址',
        cancel_booking: '取消订单',
        confirmation_letter: '确认函(含价格)',
        hotel_confirmation_letter: '酒店确认函',
        voucher: '入住单',
        hotel_voucher: '酒店入住单',
        booking_voucher: '入住单',
        booking_voucher_eps: '入住单',
        hotel_confirm_no: '酒店确认号',
        all_date: '所有日期',
        booking_date: '预订日期',
        cancel_date: '最迟免费取消日',
        other_booking_no: '其他单号',
        booking_status: '订单状态',
        list_mode : '列表式',
        card_mode : '卡片式',
        opt_account: '操作账号',
        account_center: '账号中心',
        account_credit_balance: '授信余额明细',
        account_setting: '个人设置',
        account_message: '消息中心',
        time: '时间',
        type: '类型',
        relevant: '相关',
        balance_changed: '额度变化',
        remark: '备注',
        content: '内容',
        appendix: '附件',
        notice_emails: '通知Email',
        message: '消息',
        message_type: '消息类型',
        message_types: {
            '0': '系统通知',
            '10': '[延时支付]付款通知',
            '15': '[延时支付]未支付订单已取消通知',
        },
        mark_read: '标记已读',
        all_read: '全部已读',
        download_pdf: '下载PDF',
        show_jidu_contact_info: '显示几度联系信息',
        preview_chi_eng_version: '预览 中英文版',
        preview_eng_version: '预览 英文版',
        lowest_price: '最低价格',
        after_tax_fees: '含税/费',
        search_keyword: '搜关键字',
        only_search_in: '只搜 {str} 内',
        cooperation: '商务合作',
        customer_service: '客户服务',
        cancelled_free_remark: '订单已免费取消。',
        cancelled_charge_remark: ' 订单已取消，取消罚金为 {pri}。',
        cancelled_unknow_remark: '订单已取消，请联系客服获取取消罚金金额。',
        work_order: '工单',
        work_order_log: '工单日志',
        work_order_type: '工单类型',
        work_order_status: '工单状态',
        problem_type: '问题类型',
        early_checkin: '提前入住',
        late_checkin: '晚点入住',
        double_room: '大床房',
        twin_room: '双床房',
        higher_floor_room: '高层房间',
        quiet_room: '安静房间',
        honeymoon_arrangement_marriage: '蜜月布置（需要提供结婚证）',
        continue_live_room: '原房续住',
        continue_live_room_remark: '需提供原房几度订单号或酒店确认号',
        other_requirements: '其他需求',
        date_before_modification: '修改前日期',
        date_after_modification: '修改后日期',
        checkin_date_change_from_to: '入住日期由 {from} 修改为 {to}',
        because: '因',
        completion_time: '完成时间',
        expected_completion_time: '预订完成时间',
        ticket_type_group: {
            '1':'未到店',
            '2':'已到店',
            '3':'已离店',
        },
        ticket_status: {
            '0':'待处理',
            '1':'处理中',
            '2':'已完成',
        },
        smoking_types: {
            '1':'吸烟',
            '0':'禁烟',
        },
        spend_way: {
            '1' : '订单支付',
            '10' : '订单取消',
            '11' : '订单退款',
            '20' : '订单结算',
            '100' : '用户充值',
        },
        payment_way: {
            '-1': '延时支付',
            '0': '授信余额',
            '1': '支付宝',
            '10': '微信',
        },
        order_hotel_status:{
            '10': '预订成功',
            '5': '正在预订',
            '3': '正在预订',
            '0': '正在预订',
            '-5': '待支付',
            '-10': '预订取消',
            '-20': '预订失败',
            '-30': '取消失败'
        },
        currency: {
            CNY: '人民币',
            USD: '美元',
            JPY: '日元',
        },
        error: {
            '-1': '用户名或密码错误',
            '-2': '您的账号正在审核中，请留意注册邮箱',
            '-30': '价格不可用',
            '-60': '订单状态异常',
            '-63': '授信余额不足',
            '-64': '未找到订单',
            '-100': '数据校验失败',
            '-101': '验证码错误',
            '-105': '{s} 已经存在，请更换其他',
            '-110': '操作频繁, 请暂停',
            '-120': '操作失败, 请重试',
            '-130': '操作不允许',
            '-403': '请求被拒绝',
            '-401': '请求未授权',
            '-404': '未找到',
            '-405': '用户邮箱不存在',
            '403': '请求被拒绝',
            '401': '请求未授权',
            '-1000': '未知错误',
        },
    },
    en_US: {
        app_name: 'Jidu Travel',
        home: 'Home',
        hotel_index: 'Hotel Reservation',
        booking_list: 'Booking Management',
        ticket_list: 'Ticket Management',
        booking_detail: 'Booking Detail',
        hotel_list: 'Hotel Search',
        hotel_detail: 'Hotel Detail',
        about_us: 'About Us',
        partners: 'Partners',
        contact_us: 'Contact Us',
        recommended_hotels: 'Recommended Hotels',
        login: 'Login',
        logout: 'Logout',
        submit: 'Submit',
        search: 'Search',
        reset: 'Reset',
        back: 'Back',
        next: 'Next',
        update: 'Update',
        cancel: 'Cancel',
        close: 'Close',
        delete: 'Delete',
        print: 'Print',
        create: 'Create',
        append: 'Append',
        upload: 'Upload',
        ok: 'OK',
        all: 'All',
        filters: 'Filters',
        clear_all: 'Clear all',
        and: 'and',
        current: 'Current',
        detail: 'Details',
        register: 'Register',
        username: 'Username',
        password: 'Password',
        confirmemail: 'Email Confirmation',
        new_password: 'New password',
        re_new_password: 'Confirm new password',
        input_username: 'Please input your username',
        input_password: 'Please input your password',
        passwords_not_match: 'Two passwords don\'t match',
        passwords_min_length: 'Password must be at least 10 characters',
        loginname_min_length: 'Username must be between 8 and 16 characters long',
        exist_login_name: '{s} already exists. Please choose another user name',
        exist_user_email: '{s} already exists. Please choose another',
        remember_me: 'Remember me',
        forgot_password: 'Forgot password',
        'find-password': 'Find password',
        reset_password: 'Reset password',
        no_account_register: 'No account? Register',
        login_expired: 'Login has expired or not login, please login again',
        user_email: 'User Email',
        user_info: 'User Info',
        vercode: 'Verification Code',
        send_email_code: 'Send email verif code',
        send_email_code_success: 'The verification code was sent successfully. Please check it',
        resend_after_s: 'Resend after {s}s',
        register_welcome: 'Welcome to register Jidu account',
        register_welcome_desc: 'We will check your registration information within 24 hours, then please pay attention to the registered email.',
        register_email_confirm: 'Your account has been registered, please verify your email',
        register_email_confirm_desc: 'We have sent a confirmation email to {s}. Please verify through the link in the email. After successful verification, we will complete the review within 24 hours and notify you by email.',
        register_email_confirm_error: 'Email verification failed',
        register_info: 'Registration',
        register_success: 'Your account is registered successfully',
        email_authentication: 'Email Confirmation',
        info_verification: 'Information Verification',
        company_info: 'Company Info',
        company_code: 'Company Code',
        company_name: 'Company Name',
        enterprise_agency: 'Enterprise',
        personal_agent: 'Personal',
        personal_info: 'Personal Info',
        company_city: 'City',
        contact_address: 'Contact Address',
        contact_tel: 'Contact Tel',
        contact_person: 'Contact Person',
        account_info: 'Account Info',
        full_name: 'Full Name',
        mobile_number: 'Mobile Number',
        booking_rules: 'Booking Rules',
        eps_terms: 'EPS Terms and Conditions',
        eps_terms_agent: 'AGENT TERMS OF USE',
        faq: 'FAQ',
        privacy_policy: 'Privacy Policy',
        service_terms: 'Service Terms',
        wechat_official_account: 'Wechat Official Account',
        org_info:'Client Info',
        credit_balance: 'Credit Balance',
        credit_quota: 'Credit Quota',
        view_details: 'View Details',
        view_details1: 'View Details',
        more_detail: 'More Details',
        messages: 'Messages',
        settings: 'Settings',
        destination: 'Destination',
        city: 'City',
        hotel: 'Hotel',
        hotel_desc: 'Description',
        checkin_date: 'Check-in',
        checkout_date: 'Check-out',
        checkin_time: 'Check-in Time',
        checkout_time: 'Check-out Time',
        hanlde_checkin_time: 'Check-in time from',
        checkin: 'Check-in',
        checkout: 'Check-out',
        checkin_min_age: 'Minimum check-in age - {n}',
        date: 'Date',
        date_range: 'Date Range',
        nights: 'Nights',
        night_unit: '1 night | {n} nights',
        room_unit: '1 room | {n} rooms',
        adult_unit: '1 adult | {n} adults',
        child_unit: '0 child | 1 child | {n} children',
        year_old: '0 year old | 1 year old | {n} years old',
        adult_unit_cn: '{n}成人',
        child_unit_cn: '{n}儿童',
        year_old_cn: '{n}岁',
        adult_unit_en: '1 adult | {n} adults',
        child_unit_en: '0 child | 1 child | {n} children',
        year_old_en: '0 year old | 1 year old | {n} years old',
        image_unit: '0 image | 1 image | {n} images',
        hotel_unit: '<span style="color: #FF5C00">0</span> hotel | <span style="color: #FF5C00">1</span> hotel | <span style="color: #FF5C00">{n}</span> hotels',
        rooms: 'Rooms',
        adults: 'Adults',
        children: 'Children',
        child_ages: 'Children\'s ages',
        per_room: 'Per Room',
        nationality: 'Nationality',
        residency: 'Residency',
        common_nationality: 'Popular',
        other_nationality: 'Other',
        nationality_remark: 'If the actual guest\'s nationality and name do not match with the booking info, it may result in extra charges, unable to modify booking and unable to check-in, etc.',
        nationality_remark_eps: 'If the actual guest\'s residency and name do not match with the booking info, it may result in extra charges, unable to modify booking and unable to check-in, etc.',
        success: 'Success',
        more: 'More',
        hide: 'Hide',
        no_data_keyword: 'No data was found, please enter other keywords',
        an_least_2_char: 'Enter at least 2 characters',
        total_items: 'Total {s} items',
        total_orders: 'Total {s} bookings',
        total_price: 'Total',
        price: 'Price',
        settlement_total_price: 'Settlement Total',
        no_limit: 'No limit',
        region: 'Region',
        neighborhood: 'Neighborhood',
        point_of_interest: 'Point Of Interest',
        star: 'Star',
        guest_rating: 'Guest Rating',
        guest_rating_sort: 'Guest Rating',
        guest_reviews: 'Based on {n} guest reviews',
        guest_rating_options:{
            '4.5': 'Wonderful 4.5+',
            '4': 'Very good 4+',
            '3.5': 'Good 3.5+',
            '3': 'So-so 3+',
        },
        g_overall: "Overall",
        g_cleanliness: "Cleanliness",
        g_service: "Service",
        g_comfort: "Comfort",
        g_condition: "Condition",
        g_location: "Location",
        g_neighborhood: "Neighborhood",
        g_quality: "Quality",
        g_value: "Cost Performance",
        g_amenities: "Amenities",
        brand: 'Brand',
        favorite: 'Favorite',
        service: 'Service',
        getting_around: 'Getting Around',
        price_highest: 'Price Highest',
        price_lowest: 'Price Lowest',
        hotel_info_loading: 'Hotel information loading ...',
        confirm_price_loading: 'Confirming price. Please wait it ...',
        hot_selling_priority: 'Hot Selling',
        favorite_priority: 'Favorite',
        star_lowest: 'Star Lowest',
        star_highest: 'Star Highest',
        hotel_location: 'Hotel Location',
        hotel_overview: 'Overview',
        hotel_policy: 'Policies',
        important_information: 'Important Information',
        guest_room: 'Rooms',
        dining: 'Dining',
        rec_spa_amenity: 'Rec, Spa, Premium Amenities',
        business_other_amenity: 'Business, Other Amenities',
        renovation_close: 'Renovation/Close Notice',
        check_out_airfare_combination_specials: 'Check out airfare combination specials',
        airfare_combination_specials: 'Airfare Combination Specials',
        hotel_size: 'Hotel Size',
        hotel_name: 'Hotel Name',
        child_extra_beds:'Children and extra beds',
        pet: 'Pet',
        special_check_in_instructions: 'Special Check-in Instructions',
        check_in_instructions: 'Check-in Instructions',
        mandatory_fees: 'Mandatory Fees',
        optional_extras: 'Optional Extras',
        know_before_you_go: 'Know Before You Go',
        important_note: 'Announcements',
        other_policy: 'Others',
        facility_service: 'Facilities and services',
        room_price: 'Room price',
        room_size: 'Room size',
        room_type: 'Room',
        room: 'Room ',
        all_room: 'All Room',
        cancel_policy: 'Cancellation Policies',
        price_room_night: 'Price Per Night',
        maximum_occupancy: 'Sleeps {n}',
        occupancy: 'Occupancy',
        occupancy_info: 'Occupancy Info',
        reserve: 'Reserve',
        reserve_now: 'Reserve Now',
        select_bedtype: 'Select bed type',
        select_bedtype_warning: 'The hotel will give priority to your choice of bed type, but there is no guarantee that it will be satisfied',
        bedtype_unit :{
            "double": '1 double bed | {n} double beds',
            "single": '1 single bed | {n} single beds',
            "single-extra-bed": '1 single-extra-bed | {n} single-extra-beds',
            "king": '1 king bed | {n} king beds',
            "queen": '1 queen bed | {n} queen beds',
            "semi-double": '1 semi-double bed | {n} semi-double beds',
            "futon": '1 futon | {n} futons',
            "sofa bed": '1 sofa bed | {n} sofa beds',
            "bunk":'1 bunk | {n} bunks',
            "water bed": '1 water bed|{n} water beds',
            "waterbed": '1 water bed|{n} water beds',
        },
        meal_type: 'Meal Type',
        included_meal: 'Included meal',
        non_refundable: 'Non-Refundable',
        non_refundable_remark: 'This is a non-refundable booking. Once confirmed, no modification or cancellation can be made.',
        refundable: 'Refundable',
        partially_refundable: 'Partially refundable',
        free_cancel_before: 'Free cancellation before {str}',
        cancel_policy_info: 'From {str}, {amount} will be charged;',
        cancel_policy_info_1: '{s} to {e}, {f} will be charged;',
        cancel_policy_info_2: 'After {e}，it is non-refundable;',
        cancel_policy_info_3: 'If you cancel the booking, {f} will be charged.',
        cancellation_fee: 'Cancellation fee',
        beijing_time_remark:'All the above are Beijing time',
        searching_for_price: 'Searching for rates, please wait...',
        no_avail: 'No Availability',
        map_view: 'Map View',
        mealtypes: {
            '0':'Room Only',
            '1':'Breakfast',
            '2':'Breakfast & Dinner',
            '3':'Breakfast & Lunch',
            '4':'Full Board',
        },
        mealtype_unit: {
            '0':'Room Only',
            '1':'Breakfast × {n}',
            '2':'Breakfast & Dinner × {n}',
            '3':'Breakfast & Lunch × {n}',
            '4':'Full Board × {n}',
        },
        mealtype_unit_cn: {
            '0':'不含餐',
            '1':'含早餐 × {n}',
            '2':'含早晚餐 × {n}',
            '3':'含早午餐 × {n}',
            '4':'含三餐 × {n}',
        },
        mealtype_unit_en: {
            '0':'Room Only',
            '1':'Breakfast × {n}',
            '2':'Breakfast & Dinner × {n}',
            '3':'Breakfast & Lunch × {n}',
            '4':'Full Board × {n}',
        },
        booking_fill: 'Fill In Booking',
        booking_confirm: 'Confirm Booking',
        booking_pay: 'Payment',
        booking_info: 'Booking Info',
        booking_id: 'Booking Id',
        immediately_confirm: 'Instant Confirmation',
        immediately_confirm_remark: 'Once the booking request is received, it will be confirmed immediately.',
        guest_name: 'Guest Name',
        guest_name_main: 'Primary',
        guest_name_other: 'Others',
        last_name: 'Last Name',
        first_name: 'First Name',
        last_name_error: 'Letters only; Space is not allowed',
        first_name_error: 'Letters only',
        contact_info: 'Contact Info',
        group_booking_id: 'Group Booking ID',
        group_booking_id_remark: 'No more than 50 characters. Only numbers, letters, "/", "-" are allowed.',
        special_request: 'Special Requests',
        special_request_remark: 'Special requests are not guaranteed and depend on hotel arrangements for the day.',
        booking_reminder: 'Booking Reminder',
        rate_remark: 'Rate Remark',
        pay_countdown_time: 'Please complete payment within {m} minutes {s} seconds, otherwise the booking will be cancelled',
        price_change: 'Price change',
        from: 'From {str}',
        to: 'To',
        room_rate: 'Room Rate',
        surcharges:'Surcharges',
        taxes_fees:'Taxes and Fees',
        sales_tax:'Sales Tax',
        property_fee:'Property Fee',
        above_total:'Above Total',
        jidu_service_charge:'Jidu Service Charge',
        pay_now: 'Pay Now',
        pay_hotel: 'Pay at hotel',
        air_ticket_info: 'Ticket Information',
        air_ticket_info_remark: 'It has been confirmed that the above passengers have booked the travel (flight, cruise, car rental or long-distance train). In the process of communication with the passengers, the room reservation will always be quoted together with the transportation reservation, and the passengers will not know the unit price of the room reservation. The travel and traffic information of the passenger reservation is as follows',
        flight_no: 'Flight NO.',
        air_ticket_no: 'Ticket NO.',
        air_departure: 'Airport Of Departure',
        air_landing: 'Airport Of Landing',
        create_booking_loading: 'Generating booking, please wait...',
        pay_booking_loading: 'Paying booking, please wait...',
        booking_loading: 'Booking in progress, please wait ...',
        payment_method: 'Payment Method',
        confirm_pay: 'Confirm pay',
        confirm_cancel: 'Confirm cancel booking',
        pay: 'Pay',
        paid_success: 'Paid successfully',
        delayed_payment: 'Deferred Payment',
        delayed_payment_desc: 'Deferred Payment',
        latest_payment_time: 'Payment Deadline',
        scaning_payment: 'WeChat Scaning',
        scaning_remark: 'Scan code use WeChat APP',
        alipay: 'Alipay',
        booking_time: 'Booking time',
        client_ref_id: 'Client ID',
        address: 'Address',
        cancel_booking: 'Cancel booking',
        confirmation_letter: 'Confirmation letter(Price included)',
        voucher: 'Voucher',
        booking_voucher: 'Voucher',
        booking_voucher_eps: 'Voucher',
        hotel_confirm_no: 'Hotel Confirmation Code',
        all_date: 'All Dates',
        booking_date: 'Booking Date',
        cancel_date: 'Cancellation Deadline',
        other_booking_no: 'Other Booking Id',
        booking_status: 'Booking Staus',
        list_mode : 'List Mode',
        card_mode : 'Card Mode',
        opt_account: 'User Account',
        account_center: 'Account Center',
        account_credit_balance: 'Credit Balance',
        account_setting: 'Settings',
        account_message: 'Messages',
        time: 'Time',
        type: 'Type',
        relevant: 'Relevant',
        balance_changed: 'Balance Changed',
        remark: 'Remark',
        content: 'Content',
        appendix: 'Appendix',
        notice_emails: 'Notification Emails',
        message: 'Message',
        message_type: 'Message Type',
        message_types: {
            '0': 'System Notification',
            '10': '[Deferred Payment]Payment Notification',
            '15': '[Deferred Payment]Cancelled Notification',
        },
        mark_read: 'Mark Read',
        all_read: 'All Read',
        download_pdf: 'Download PDF',
        show_jidu_contact_info: 'Show Jidu contact info',
        preview_chi_eng_version: 'Preview chinese/english version',
        preview_eng_version: 'Preview english version',
        lowest_price: 'Lowest',
        after_tax_fees: 'After tax & fees',
        search_keyword: 'Search By Keyword',
        only_search_in: 'Only Search In {str}',
        cooperation: 'Cooperation',
        customer_service: 'Customer Service',
        cancelled_free_remark: 'Booking is canceled and fully refunded.',
        cancelled_charge_remark: 'Booking is canceled. Cancellation fee is {pri}.',
        cancelled_unknow_remark: 'Booking is canceled. Please contact customer service for cancellation fee.',
        work_order: 'Work Order',
        work_order_log: 'Work Order Log',
        work_order_type: 'Work Order Type',
        work_order_status: 'Work Order Status',
        problem_type: 'Problem Type',
        early_checkin: 'Early Check-In',
        late_checkin: 'Late Check-In',
        double_room: 'Double Room',
        twin_room: 'Twin Room',
        higher_floor_room: 'Higher Floor Room',
        quiet_room: 'Quiet Room',
        honeymoon_arrangement_marriage: 'Honeymoon arrangement (marriage certificate required)',
        continue_live_room: 'Continue to live in the original room',
        continue_live_room_remark: 'Please provide the jidu booking id or hotel confirmation code of the original room',
        other_requirements: 'Other requirements',
        date_before_modification: 'Date before modification',
        date_after_modification: 'Date after modification',
        checkin_date_change_from_to: 'Check-in date is changed from {from} to {to}',
        because: 'Because',
        completion_time: 'Completion Time',
        expected_completion_time: 'Expected Completion Time',
        ticket_type_group: {
            '1':'Not checkin',
            '2':'Checked in',
            '3':'Checked out',
        },
        ticket_status: {
            '0':'Not Processed',
            '1':'Processing',
            '2':'Completed',
        },
        smoking_types: {
            '1':'Smoking',
            '0':'Non-Smoking',
        },
        spend_way: {
            '1': 'Pay booking',
            '10': 'Cancel booking',
            '11': 'Refund booking',
            '20': 'Settle booking',
            '100': 'User top-up',
        },
        order_hotel_status:{
            '10': 'Success',
            '5': 'Pending',
            '3': 'Pending',
            '0': 'Pending',
            '-5': 'Unpaid',
            '-10': 'Canceled',
            '-20': 'Failed',
            '-30': 'CancelFailed'
        },
        payment_way: {
            '-1': 'Deferred Payment',
            '0': 'Credit Balance',
            '1': 'Alipay',
            '10': 'Wechat',
        },
        currency: {
            CNY: 'Chinese Yuan',
            USD: 'U.S. Dollar',
            JPY: 'Japanese Yen',
        },
        error: {
            '-1': 'User name or password error',
            '-2': 'Your account is currently under review. Please check your registered email for updates.',
            '-30': 'Not available',
            '-60': 'Booking status exception',
            '-63': 'Credit balance not enough',
            '-64': 'Not find booking',
            '-100': 'Data validation failed',
            '-101': 'Verification code error',
            '-105': '{s} already exists. Please choose another',
            '-110': 'Frequent operation, please pause',
            '-120': 'Operation failed, please try again',
            '-130': 'Operation not allowed',
            '-403': 'Forbidden Request',
            '-401': 'Unauthorized Request',
            '-404': 'Not Find',
            '-405': 'User email does not exist',
            '403': 'Forbidden Request',
            '401': 'Unauthorized Request',
            '-1000': 'Unknown error',
        },
    }
}

export default messages