import {createRouter, createWebHistory} from 'vue-router'
import {checkAuthorization} from '../utils/request'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

const router = createRouter({
    history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                title: '首页',
                index: 1
            }
        },
        {
            path: '/account/find-password',
            name: 'find-password',
            component: () => import('@/views/account/FindPassword.vue'),
            meta: {
                title: '找回密码',
                index: 2
            }
        },
        {
            path: '/account/register',
            name: 'register',
            component: () => import('@/views/account/Register.vue'),
            meta: {
                title: '注册',
                index: 3
            }
        },
        {
            path: '/account/confirmemail',
            name: 'confirmemail',
            component: () => import('@/views/account/ConfirmEmail.vue'),
            meta: {
                title: '邮箱验证',
                index: 3
            }
        },
        {
            path: '/account/login',
            name: 'login',
            component: () => import('@/views/account/Login.vue'),
            meta: {
                title: '登录',
                index: 3
            }
        },
        {
            path: '/hotel/index',
            name: 'hotel_index',
            component: () => import('@/views/hotel/Index.vue'),
            meta: {
                title: '酒店首页',
                index: 4
            }
        },
        {
            path: '/hotel/list/:CityCode',
            name: 'hotel_list',
            component: () => import('@/views/hotel/List.vue'),
            meta: {
                title: '酒店列表',
                index: 5
            }
        },
        {
            path: '/hotel/detail/:HotelId',
            name: 'hotel_detail',
            component: () => import('@/views/hotel/Detail.vue'),
            meta: {
                title: '酒店详细',
                index: 6
            }
        },
        {
            path: '/booking/fill/:PreOrderKey',
            name: 'booking_fill',
            component: () => import('@/views/booking/Fill.vue'),
            meta: {
                title: '订单填写',
                index: 7
            }
        },
        {
            path: '/booking/pay/:OrderId',
            name: 'booking_pay',
            component: () => import('@/views/booking/Pay.vue'),
            meta: {
                title: '订单支付',
                index: 8
            }
        },
        {
            path: '/booking/list',
            name: 'booking_list',
            component: () => import('@/views/booking/List.vue'),
            meta: {
                title: '订单管理',
                index: 9
            }
        },
        {
            path: '/booking/detail/:OrderId',
            name: 'booking_detail',
            component: () => import('@/views/booking/Detail.vue'),
            meta: {
                title: '订单详细',
                index: 10
            }
        },
        {
            path: '/booking/voucher/:OrderId',
            name: 'booking_voucher',
            component: () => import('@/views/booking/Voucher.vue'),
            meta: {
                title: '入住单',
                index: 11
            }
        },
        {
            path: '/booking/vouchere/:OrderId',
            name: 'booking_voucher_eps',
            component: () => import('@/views/booking/VoucherEps.vue'),
            meta: {
                title: '入住单',
                index: 11
            }
        },
        {
            path: '/account/index',
            name: 'account_center',
            component: () => import('@/views/account/Index.vue'),
            meta: {
                title: '账号中心',
                index: 13
            }
        },
        {
            path: '/account/settings',
            name: 'account_setting',
            component: () => import('@/views/account/Setting.vue'),
            meta: {
                title: '个人设置',
                index: 14
            }
        },
        {
            path: '/account/credit/balance',
            name: 'account_credit_balance',
            component: () => import('@/views/account/CreditBalance.vue'),
            meta: {
                title: '授信余额明细',
                index: 15
            }
        },
        {
            path: '/account/messages',
            name: 'account_message',
            component: () => import('@/views/account/Message.vue'),
            meta: {
                title: '消息中心',
                index: 16
            }
        },
        {
            path: '/home/booking-rules',
            name: 'booking_rules',
            component: () => import('@/views/help/BookingRules.vue'),
            meta: {
                title: '预订规则',
                index: 20
            }
        },
        {
            path: '/home/eps-terms',
            name: 'eps_terms',
            component: () => import('@/views/help/EpsTerms.vue'),
            meta: {
                title: '供应商预订条款',
                index: 20
            }
        },
        {
            path: '/home/eps-terms-agent',
            name: 'eps_terms_agent',
            component: () => import('@/views/help/EpsTermsAgent.vue'),
            meta: {
                title: '补充条款',
                index: 20
            }
        },
        {
            path: '/home/faq',
            name: 'faq',
            component: () => import('@/views/help/Faq.vue'),
            meta: {
                title: '常见问题',
                index: 21
            }
        },
        {
            path: '/home/privacy-policy',
            name: 'privacy_policy',
            component: () => import('@/views/help/PrivacyPolicy.vue'),
            meta: {
                title: '隐私政策',
                index: 22
            }
        },
        {
            path: '/home/terms-service',
            name: 'service_terms',
            component: () => import('@/views/help/TermsService.vue'),
            meta: {
                title: '服务条款',
                index: 23
            }
        },
        {
            path: '/ticket/list',
            name: 'ticket_list',
            component: () => import('@/views/ticket/List.vue'),
            meta: {
                title: '工单管理',
                index: 24
            }
        },
    ]
})

// 不需要登录拦截的路由配置
const loginIgnore = {
    names: ['home','find-password','register','confirmemail','login', 'booking_rules', 'faq', 'privacy_policy', 'service_terms','404', '403'],      //根据路由名称匹配
    paths: ['/'],   //根据路由fullPath匹配
    /**
     * 判断路由是否包含在该配置中
     * @param route vue-router 的 route 对象
     * @returns {boolean}
     */
    includes(route) {
        return this.names.includes(route.name) || this.paths.includes(route.path)
    }
}

/**
 * 加载导航守卫
 * @param options
 */
function loadGuards(options) {
    const {router, i18n, message} = options

    router.beforeEach((to, from,next)=>{
        if(to.name) {
            document.title = i18n.global.t(to.name) + ' - ' + i18n.global.t('app_name')
        }

        if (!NProgress.isStarted()) {
            NProgress.start()
        }

        if (!loginIgnore.includes(to) && !checkAuthorization()) {
            message.warning(i18n.global.t('login_expired'))
            next({name: 'login', query: { backurl: to.href } })
        } else {
            next()
        }
    })

    router.afterEach(()=>{
        NProgress.done()
    })
}

export { router, loadGuards}