const BASE_URL = process.env.VUE_APP_API_BASE_URL
module.exports = {
    ACCOUNT_LOGIN: `${BASE_URL}/Account/Login`,
    ACCOUNT_GETCURRENTUSER: `${BASE_URL}/Account/GetCurrentUser`,
    ACCOUNT_SENDVERICODE: `${BASE_URL}/Account/SendVeriCode`,
    ACCOUNT_SENDVERICODEFORREG: `${BASE_URL}/Account/SendVeriCodeForReg`,
    ACCOUNT_EXISTLOGINNAME: `${BASE_URL}/Account/ExistLoginName`,
    ACCOUNT_EXISTUSEREMAIL: `${BASE_URL}/Account/ExistUserEmail`,
    ACCOUNT_CONFIRMEMAIL: `${BASE_URL}/Account/ConfirmEmail`,
    ACCOUNT_RESETPWD: `${BASE_URL}/Account/ResetPassword`,
    ACCOUNT_REGISTER: `${BASE_URL}/Account/Register1`,
    ACCOUNT_UPDATE_INFO: `${BASE_URL}/Account/UpdateInfo`,
    ACCOUNT_UPDATE_PWD: `${BASE_URL}/Account/UpdatePwd`,
    ACCOUNT_NOTICE_LIST: `${BASE_URL}/Account/GetNoticeList`,
    ACCOUNT_NOTICE_READ: `${BASE_URL}/Account/ReadNotice`,
    ACCOUNT_NOTICE_DELETE: `${BASE_URL}/Account/DeleteNotice`,
    ACCOUNT_NOTICE_READALL: `${BASE_URL}/Account/ReadAllNotice`,
    HOTEL_SEARCH_KEYWORD: `${BASE_URL}/V2/Hotel/SearchKeywords`,
    HOTEL_TOP_HOTELS: `${BASE_URL}/V2/Hotel/TopCityHotels`,
    HOTEL_GET_LIST_FILTERS: `${BASE_URL}/V2/Hotel/GetHotelListFilters`,
    HOTEL_SEARCH_HOTELS: `${BASE_URL}/V2/Hotel/SearchHotels`,
    HOTEL_DETAIL: `${BASE_URL}/V2/Hotel/GetHotelDetail`,
    HOTEL_FAVORITE: `${BASE_URL}/Hotel/SaveFavorite`,
    HOTEL_FAVORITE_CANCEL: `${BASE_URL}/Hotel/CancelFavorite`,
    HOTEL_SEARCH_ROOMS: `${BASE_URL}/V2/Hotel/SearchRooms`,
    HOTEL_CONFIRM_RATE: `${BASE_URL}/V2/Hotel/ConfirmRate`,
    BOOKING_CREATE: `${BASE_URL}/Booking/Create`,
    BOOKING_CANCEL: `${BASE_URL}/V2/Booking/Cancel`,
    BOOKING_CANCEL_FEE: `${BASE_URL}/V2/Booking/GetCancelFee`,
    BOOKING_GET: `${BASE_URL}/V2/Booking/GetOrderInfo`,
    BOOKING_GET_STATUS: `${BASE_URL}/Booking/GetOrderStatus`,
    BOOKING_LIST: `${BASE_URL}/Booking/SearchOrders`,
    PAY_ALIPAY_PAGE: `${BASE_URL}/Pay/Alipay/PagePay`,
    PAY_CREDIT: `${BASE_URL}/Pay/Credit`,
    PAY_CREDIT_LIST: `${BASE_URL}/Pay/Credit/List`,
    PAY_DELAYED: `${BASE_URL}/Pay/Delayed`,
    TICKET_TYPES: `${BASE_URL}/Ticket/GetTypes`,
    TICKET_UPLOAD: `${BASE_URL}/Ticket/Upload`,
    TICKET_CREATE: `${BASE_URL}/Ticket/Create`,
    TICKET_GETLISTBYORDERID: `${BASE_URL}/Ticket/GetTicketListByOrderId`,
    TICKET_GETLIST: `${BASE_URL}/Ticket/GetTicketList`,
}